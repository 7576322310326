import React from 'react';
import TransitionLink from 'gatsby-plugin-transition-link';
import Markdown from 'markdown-to-jsx';

import useObserver from '../../../hooks/useObserver';
import './styles.scss';

const AboutSection = ({ text, image }) => {
  const { ref, hasEntered } = useObserver({ rootMargin: '0px 0px -100px 0px' });

  return (
    <section className={`about ${!hasEntered && 'fade-in'}`} ref={ref}>
      <div className="about__text">
        <Markdown>{text}</Markdown>
        <p>
          <TransitionLink
            to="/about"
            exit={{
              length: 0.2,
            }}
            entry={{ delay: 0.2, length: 0 }}
          >
            More about Lisa
          </TransitionLink>
        </p>
      </div>
      <img className="about__img" src={image} alt="" />
    </section>
  );
};

export default AboutSection;

/* <AboutSection
  ref={aboutRef}
  className={!aboutHasEntered ? 'fade-in' : undefined}
>
  <AboutText>
    <Markdown options={options}>{about_blurb}</Markdown>
    <P>
      <TransitionLink
        to="/about"
        exit={{
          length: 0.2,
        }}
        entry={{ delay: 0.2, length: 0 }}
      >
        More about Lisa
      </TransitionLink>
    </P>
  </AboutText>
  <img src={secondary_image} alt="" />
</AboutSection>; */

//       const AboutSection = styled.section`
//   display: flex;
//   flex-direction: column;
//   padding: 0 0 5rem;
//   transition: transform 1s, opacity 1s;

//   @media (min-width: 800px) {
//     padding: 5rem 6vw 5rem 8vw;
//     flex-direction: row;
//     justify-content: space-between;
//     align-items: center;
//   }

//   @media (min-width: 1200px) {
//     margin-top: 5rem;
//     padding: 0 6vw 5rem 12vw;
//   }

//   @media (min-width: 1200px) {
//     padding: 0 10vw 5rem 12vw;
//   }

//   img {
//     width: 70vw;
//     align-self: flex-end;

//     @media (min-width: 800px) {
//       max-height: none;
//       width: 30vw;
//     }
//   }
// `;

// const AboutText = styled.div`
//   padding: 0 2rem 5rem 1.5rem;

//   @media (min-width: 800px) {
//     padding: 0;
//     max-width: 24rem;
//   }
//   @media (min-width: 1000px) {
//     max-width: 32rem;
//   }
// `;
