import React from 'react';
import useObserver from '../../../hooks/useObserver';
import './styles.scss';

const OpeningSection = ({ image }) => {
  const { ref, hasEntered } = useObserver({ rootMargin: '0px 0px -100px 0px' });

  return (
    <section ref={ref} className={`opening ${!hasEntered && 'fade-in'}`}>
      <img className="opening__img" src={image} alt="" />
    </section>
  );
};

export default OpeningSection;

/* <OpeningSection ref={ref} className={!hasEntered ? 'fade-in' : undefined}>
        <img src={opening_image} alt="" />
      </OpeningSection> */

//       const OpeningSection = styled.section`
//   padding: 10rem 5rem 5rem 0;
//   transition: transform 1s, opacity 1s;

//   img {
//     width: 85vw;

//     @media (min-width: 800px) {
//       width: 70vw;
//     }
//   }
// `;
