import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';
import useObserver from '../../hooks/useObserver';

const InstagramSection = () => {
  const username = 'lisamortsmakeup';
  const { ref, hasEntered } = useObserver({
    rootMargin: '0px 0px -200px 0px',
  });

  return (
    <StaticQuery
      query={graphql`
        {
          allInstaNode(limit: 6, sort: { fields: timestamp, order: DESC }) {
            edges {
              node {
                id
                preview
                caption
              }
            }
          }
        }
      `}
      render={data => {
        const { edges } = data.allInstaNode;
        return (
          <Container ref={ref}>
            <Row>
              {edges.map(post => {
                const { id, preview, caption } = post.node;
                return (
                  <a
                    key={id}
                    href={`https://www.instagram.com/p/${id}/`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={!hasEntered ? 'fade-in' : undefined}
                  >
                    <img src={preview} alt={caption} />
                  </a>
                );
              })}
            </Row>
            <Text className={!hasEntered ? 'fade-in' : undefined}>
              <h2 className="big">Follow Lisa on Instagram</h2>
              <p>
                <a
                  href={`https://www.instagram.com/${username}/`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  @{username}
                </a>
              </p>
            </Text>
          </Container>
        );
      }}
    />
  );
};

export default InstagramSection;

const Container = styled.section`
  padding: 5rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Row = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  margin: 0 0 2.5rem;

  a {
    margin: 0;
    flex: 1;
    display: none;
    border: none;

    &:nth-child(-n + 3) {
      display: block;
    }

    &:nth-child(-n + 2) {
      margin: 0 0.75rem 0 0;
    }

    @media (min-width: 600px) {
      &:nth-child(-n + 2) {
        margin: 0 1.5rem 0 0;
      }
    }

    @media (min-width: 900px) {
      &:nth-child(-n + 4) {
        display: block;
      }
      &:nth-child(-n + 3) {
        margin: 0 1.5rem 0 0;
      }
    }

    @media (min-width: 1300px) {
      &:nth-child(-n + 5) {
        display: block;
      }

      &:nth-child(-n + 4) {
        margin: 0 1.5rem 0 0;
      }
    }

    @media (min-width: 1800px) {
      &:nth-child(-n + 6) {
        display: block;
      }

      &:nth-child(-n + 5) {
        margin: 0 1.5rem 0 0;
      }
    }

    img {
      display: block;
      width: 100%;
    }

    &:nth-child(1) {
      transition: all 600ms;
    }

    &:nth-child(2) {
      transition: all 600ms 100ms;
    }

    &:nth-child(3) {
      transition: all 600ms 200ms;
    }

    &:nth-child(4) {
      transition: all 600ms 300ms;
    }

    &:nth-child(5) {
      transition: all 600ms 400ms;
    }

    &:nth-child(6) {
      transition: all 600ms 500ms;
    }
  }
`;

const Text = styled.div`
  transition: all 1s 400ms;

  h2,
  p {
    text-align: center;
    max-width: 30rem;
  }
`;
