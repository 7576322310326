import React from 'react';
import TransitionLink from 'gatsby-plugin-transition-link';
import Markdown from 'markdown-to-jsx';

import useObserver from '../../../hooks/useObserver';
import './styles.scss';

const ContactSection = ({ text }) => {
  const { ref, hasEntered } = useObserver({ rootMargin: '0px 0px -100px 0px' });

  return (
    <section ref={ref} className={`contact ${!hasEntered && 'fade-in'}`}>
      <Markdown>{text}</Markdown>
      <p>
        <TransitionLink
          to="/contact"
          exit={{
            length: 0.2,
          }}
          entry={{ delay: 0.2, length: 0 }}
        >
          Get in touch
        </TransitionLink>
      </p>
    </section>
  );
};

export default ContactSection;

/* <ContactSection
        ref={contactRef}
        className={!contactHasEntered ? 'fade-in' : undefined}
      >
        <Markdown options={options}>{contact_blurb}</Markdown>
        <p>
          <TransitionLink
            to="/contact"
            exit={{
              length: 0.2,
            }}
            entry={{ delay: 0.2, length: 0 }}
          >
            Get in touch
          </TransitionLink>
        </p>
      </ContactSection> */

//       const ContactSection = styled.section`
//   padding: 5rem 1.5rem;
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   transition: transform 1s, opacity 1s;

//   @media (min-width: 800px) {
//     padding: 5rem;
//   }

//   h1 {
//     text-align: center;
//     max-width: 22rem;

//     @media (min-width: 1000px) {
//       max-width: 30rem;
//     }
//   }
// `;
