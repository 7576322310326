import React, { useContext } from 'react';
import TransitionLink from 'gatsby-plugin-transition-link';
import { OverlayContext } from '../../../contexts/overlayContext';
import useObserver from '../../../hooks/useObserver';
import './styles.scss';

const PortfolioSection = ({ image }) => {
  const { ref, hasEntered } = useObserver({ rootMargin: '0px 0px -100px 0px' });
  const overlayContext = useContext(OverlayContext);

  return (
    <section className={`portfolio ${!hasEntered && 'fade-in'}`}>
      <img className="portfolio__img" ref={ref} src={image} alt="" />
      <p>
        <TransitionLink
          to="/portfolio"
          exit={{
            trigger: () => overlayContext?.setShowOverlay(true),
            length: 0.7,
          }}
          entry={{ delay: 0.7, length: 0 }}
        >
          View portfolio
        </TransitionLink>
      </p>
    </section>
  );
};

export default PortfolioSection;

/* <PortfolioSection className={!folioHasEntered ? 'fade-in' : undefined}>
        <img ref={folioRef} src={portfolio_image} alt="" />
        <P>
          <TransitionLink
            to="/portfolio"
            exit={{
              trigger: () => overlayContext.setShowOverlay(true),
              length: 0.7,
            }}
            entry={{ delay: 0.7, length: 0 }}
          >
            View portfolio
          </TransitionLink>
        </P>
      </PortfolioSection> */

//       const PortfolioSection = styled.section`
//   padding: 5rem 0;
//   width: 100%;
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   transition: transform 1s, opacity 1s;

//   @media (min-width: 800px) {
//     padding: 5rem;
//   }

//   img {
//     display: block;
//     width: 100%;
//     margin: 0 0 1.5rem;

//     @media (min-width: 800px) {
//       width: 80vw;
//       max-width: 1200px;
//     }

//     @media (min-width: 1000px) {
//       width: 70vw;
//     }
//   }
// `;
